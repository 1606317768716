import React, { useState } from 'react';
import Modal from 'react-modal';
import '../stylesheets/LoginModal.css';
import '../stylesheets/DeleteModal.css';
import "../stylesheets/ContactsUrgence.css"

// if typeDelete == 0 then the delete is for the contact 
// if typeDelete == 1 then the delete is for the account 

Modal.setAppElement('#root');

const DeleteModal = ({ isOpen, onRequestClose, onDelete, index, typeDelete }) => {

    const [title] = useState(() => {
        if (typeDelete === 0)
            return "Attention : Suppression d'un Contact d'Urgence";
        return "Attention : Suppression de votre compte"
    });
    const [description] = useState(() => {
        if (typeDelete === 0)
            return "Ce contact est marqué comme un contact d'urgence. Le supprimer pourrait avoir des conséquences importantes en cas de besoin.\nEs-tu sûr de vouloir continuer et supprimer ce contact d'urgence ? Cette action est irréversible.";
        return "En procédant à la suppression, toutes vos données seront effacées de manière permanente. Vous ne pourrez plus accéder à votre compte ni aux services associés.\nnEs-tu sûr de vouloir continuer et supprimer votre compte ? Cette action est irréversible"
    });

    const handleDel = (e) => {
        e.preventDefault();
        if (typeDelete === 1)
            onDelete()
        else
            onDelete(index);
        onRequestClose();
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Login Modal"
            className="modal"
            overlayClassName="modal-overlay"
        >
            <div className="popup-delete">
                <div className='popup-title'>
                    <h2 style={{ color: "white" }}> {title} </h2>
                    <p style={{ color: "white" }}> {description} </p>
                </div>
                <div className='popup-description'>
                    <button onClick={handleDel} className='submit-contact'>Oui</button>
                    <button onClick={onRequestClose} className='submit-contact'>Non</button>
                </div>
            </div>
        </Modal>
    );
};

export default DeleteModal;
