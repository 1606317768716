// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup-delete {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    background: #2F4E78;
    border-radius: 8px;
    outline: none;
    width: 400px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
}

.popup-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.popup-description {
    display: flex;
    align-items: center;
    justify-content: space-around;
}`, "",{"version":3,"sources":["webpack://./src/stylesheets/DeleteModal.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,gCAAgC;IAChC,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,aAAa;IACb,YAAY;IACZ,yCAAyC;AAC7C;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,6BAA6B;AACjC","sourcesContent":[".popup-delete {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    right: auto;\n    bottom: auto;\n    margin-right: -50%;\n    transform: translate(-50%, -50%);\n    padding: 20px;\n    background: #2F4E78;\n    border-radius: 8px;\n    outline: none;\n    width: 400px;\n    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);\n}\n\n.popup-title {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n}\n\n.popup-description {\n    display: flex;\n    align-items: center;\n    justify-content: space-around;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
