import React, { useEffect, useState } from 'react';
import '../stylesheets/Urgence.css';
import MapWithPopup from '../components/Map';
import Modal from "../components/Modal";
import '../stylesheets/Map.css';
import { useNavigate } from 'react-router-dom';

export default function Urgence() {
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [modalOpen, setModalOpen] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.size < 1) {
      navigate('/home');
      return;
    }
  }, [navigate]);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return (
    <div className='App'>
      <div style={{ height: windowSize.innerHeight * 0.065, width: windowSize.innerWidth, display: 'flex', justifyContent: 'left', alignItems: 'center' }} className="rectangle one">
      </div>
      <div style={{ display: 'flex' }}>
        <div style={{ height: windowSize.innerHeight * 0.917, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} className="rectangle two">
          <MapWithPopup />
          <div className='map'>
            {modalOpen && <Modal setOpenModal={setModalOpen} />}
          </div>
        </div>
      </div>
      <div style={{ height: windowSize.innerHeight * 0.04, width: windowSize.innerWidth, display: 'flex', justifyContent: 'center', alignItems: 'center' }} className="rectangle two">
      </div>
    </div>
  );
}

export function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}



