import React, { useState, useEffect } from 'react';
import InfoItem from './InfoItem';
import "../stylesheets/MonProfil.css"
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { auth, db } from '../scripts/firebase';

function InfoList() {

    const [userInfo, setUserInfo] = useState({
        lastName: '',
        firstName: '',
        name: '',
        phone: ''
    });

    const [isAuthenticated, setIsAuthenticated] = useState(true);

    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                const user = auth.currentUser;
                console.log(user)
                if (user) {
                    setIsAuthenticated(true)
                    const uid = user.uid;
                    const userDocRef = doc(db, 'users', uid);
                    const userDocSnap = await getDoc(userDocRef);

                    if (userDocSnap.exists()) {
                        const data = userDocSnap.data();
                        setUserInfo({
                            lastName: data.lastName || '',
                            firstName: data.firstName || '',
                            name: data.name || '',
                            phone: data.phone || ''
                        });
                    } else {
                        console.log("No such document!");
                    }
                } else {
                    setIsAuthenticated(false)
                    console.log("Aucun utilisateur n'est connecté");
                }
            } catch (error) {
                console.error("Error fetching user info:", error);
            }
        };

        fetchUserInfo();
    }, []);



    const editInfos = async (category, item) => {
        try {
            const user = auth.currentUser;
            if (user) {
                const uid = user.uid;
                const userDocRef = doc(db, 'users', uid);

                // Update the field in Firebase based on category
                await updateDoc(userDocRef, {
                    [category]: item
                });

                // Update the local state to reflect the changes
                setUserInfo(prevInfo => ({
                    ...prevInfo,
                    [category]: item
                }));
            } else {
                setIsAuthenticated(false)
                console.log("Aucun utilisateur n'est connecté");
            }
        } catch (error) {
            console.error("Error updating user info:", error);
        }
    };

    return (
        <div>
            {!isAuthenticated ? (
                <div className="error-message">
                    Merci de vous connecter
                </div>
            ) : (
                <>
                    <InfoItem
                        dataName="lastName"
                        category="Nom"
                        item={userInfo.lastName}
                        editInfos={editInfos}
                    />
                    <InfoItem
                        dataName="firstName"
                        category="Prénom"
                        item={userInfo.firstName}
                        editInfos={editInfos}
                    />
                    <InfoItem
                        dataName="name"
                        category="Identifiant"
                        item={userInfo.name}
                        editInfos={editInfos}
                    />
                    <InfoItem
                        dataName="phone"
                        category="Numero"
                        item={userInfo.phone}
                        editInfos={editInfos}
                    />
                </>
            )}
        </div>
    );

}

export default InfoList;
