import { BrowserRouter, Routes, Route } from "react-router-dom";
import React from 'react';
import './stylesheets/Urgence.css';
import Urgence from './pages/Urgence';
import Home from './pages/Home';
import ContactsUrgence from "./pages/ContactsUrgence";
import Navbar from './components/Navbar';
import MonProfil from "./pages/MonProfil";

export default function App() {
  return (
    <BrowserRouter>
    <Navbar/>
      <Routes>
        <Route path="/" element={<Urgence />} />
        <Route path="/home" element={<Home />} />
        <Route path="/contactsurgence" element={<ContactsUrgence />} />
        <Route path="/monprofil" element={<MonProfil />} />
      </Routes>
    </BrowserRouter>
  );
}



