import React, { useState, useEffect } from 'react';
import MonCompte from '../components/MonCompte';
import ContactList from '../components/ContactList';
import AddContactPopup from '../components/AddContactPopup';
import "../stylesheets/ContactsUrgence.css"
import { IoMdAddCircleOutline } from "react-icons/io";
import { IconContext } from "react-icons";
import { useNavigate } from 'react-router-dom';
import { auth, lireDonneesFirebase, db, } from '../scripts/firebase';
import { collection, getDocs, doc, query, where, updateDoc, arrayUnion, arrayRemove } from 'firebase/firestore'


export default function ContactsUrgence() {

  const [contacts, setContacts] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const user = auth.currentUser;

    if (!user) {
      navigate('/home');
    }
  }, [navigate]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const user = auth.currentUser;
        if (user) {

          setIsAuthenticated(true)
          const uid = user.uid;
          const q = query(collection(db, 'users'), where('uid', '==', uid));
          const querySnapshot = await getDocs(q);
          const dataArray = querySnapshot.docs.map(doc => doc.data());

          if (!querySnapshot.empty) {
            const docData = querySnapshot.docs[0].data();

            if (docData.contacts) {
              const parsedContacts = docData.contacts.map(contactStr => JSON.parse(contactStr));
              setContacts(parsedContacts);
            }
          }
        } else {
          setIsAuthenticated(false)
          console.log("Aucun utilisateur n'est connecté");
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des contacts:", error);
      }
    };

    fetchData();
  }, []);


  const addContact = async (newContact) => {
    newContact.switchState = false
    try {
      const user = auth.currentUser;
      if (user) {
        const uid = user.uid;
        const q = query(collection(db, 'users'), where('uid', '==', uid));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const userDoc = querySnapshot.docs[0];
          const userDocRef = doc(db, 'users', userDoc.id);

          const newContactStr = JSON.stringify(newContact);

          await updateDoc(userDocRef, {
            contacts: arrayUnion(newContactStr)
          });

          setContacts(prevContacts => [...prevContacts, newContact]);
        }
      }
    } catch (error) {
      console.error("Erreur lors de l'ajout du contact:", error);
    }
  };

  const deleteContact = async (index) => {
    try {
      const contactToDelete = contacts[index];
      const user = auth.currentUser;
      if (user) {
        const uid = user.uid;
        const q = query(collection(db, 'users'), where('uid', '==', uid));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const userDoc = querySnapshot.docs[0];
          const userDocRef = doc(db, 'users', userDoc.id);

          const contactStr = JSON.stringify(contactToDelete);

          await updateDoc(userDocRef, {
            contacts: arrayRemove(contactStr)
          });

          setContacts(prevContacts => prevContacts.filter((_, i) => i !== index));
        }
      }
    } catch (error) {
      console.error("Erreur lors de la suppression du contact:", error);
    }

  };

  const updateContact = async (index, updatedContact) => {
    try {
      const contactToUpdate = contacts[index];
      updatedContact.switchState = contactToUpdate.switchState
      const user = auth.currentUser;
      if (user) {
        const uid = user.uid;
        const q = query(collection(db, 'users'), where('uid', '==', uid));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const userDoc = querySnapshot.docs[0];
          const userDocRef = doc(db, 'users', userDoc.id);

          const contactStr = JSON.stringify(contactToUpdate);
          const updatedContactStr = JSON.stringify(updatedContact);

          await updateDoc(userDocRef, {
            contacts: arrayRemove(contactStr)
          });

          await updateDoc(userDocRef, {
            contacts: arrayUnion(updatedContactStr)
          });

          setContacts(prevContacts => prevContacts.map((contact, i) => i === index ? updatedContact : contact));

        }
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour du contact:", error);
    }
  };

  return (
    <div className='container-contact'>
      {!isAuthenticated ? (
        <div className="error-message">
          Merci de vous connecter
        </div>
      ) : (
        <>
          <MonCompte></MonCompte>
          <h2 className='title-content'>Liste des contacts
            <button className='button-add' onClick={() => setIsPopupOpen(true)}>
              <IoMdAddCircleOutline />
            </button>
          </h2>

          <ContactList
            contacts={contacts}
            deleteContact={deleteContact}
            updateContact={updateContact}
          />
          {isPopupOpen &&
            <AddContactPopup
              addContact={addContact}
              closePopup={() => setIsPopupOpen(false)}
            />
          }
        </>
      )}
    </div>
  );
}



